export default function EmptyRowsRenderer() {
    return (
        <div style={{ textAlign: 'center', gridColumn: '1/-1' }}>
            <p>
                The filter criteria you selected has resulted in no results
                <br />
                Please change your filters and try again
            </p>
        </div>
    );
}
