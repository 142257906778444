import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import useAuth from '../hooks/useAuth';

export default function Layout() {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();

    if (auth.viewLevel === "adminreports" && auth.role !== "SuperAdmin" && auth.role !== "PowerUser") {
        navigate('/unauthorized');
    }

    return (
      <div>
        <NavMenu />
        <Container>
          <Outlet />
        </Container>
      </div>
    );
}
