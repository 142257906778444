import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useFilter from '../hooks/useFilter';
import useAuth from "../hooks/useAuth";

export default function LearningPlans() {
    const { auth, setAuth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const { filter } = useFilter();
    const [loading, setLoading] = useState(true);
    const [selectAll, setSelectAll] = useState(true);
    const [selected, setSelected] = useState([]);
    const [customSelected, setCustomSelected] = useState([]);
    const [learningPlans, setLearningPlans] = useState([]);

    useEffect(() => {
        const getLearningPlans = async () => {
            if(filter?.formLearningPlans)
            {
                setLearningPlans(filter.formLearningPlans);
                if(filter?.learningPlans.length == filter?.formLearningPlans.length){
                    setSelectAll(true); 
                    setSelected(filter?.formLearningPlans);               
                }
                else
                {
                    setSelectAll(false);
                    var selected = filter?.formLearningPlans.filter(learningPlan => filter?.learningPlans.includes(learningPlan.value))
                    setCustomSelected(selected);
                    setSelected(selected);
                }                
            }
            else
            {
                try {
                    var path = auth.viewLevel === "adminreports" ? '/api/core/learningplans' : '/api/core/learningplans/mylearningplans';
                    await axiosPrivate.get(path)
                        .then(response =>{
                            var learningPlans = response.data.map(learningPlan => ({ value: learningPlan.id, label: learningPlan.name }));
                            setLearningPlans(learningPlans);
                            setSelected(learningPlans);
                        });
                }
                catch(err){
                    console.log(err);
                }
            }            
        };
        getLearningPlans().then(function(){
            setLoading(false);
        });
    }, []);

    const onChange = (value) => {
        setSelectAll(value);
        if(value == true){
            setSelected(learningPlans);
        }
        else{
            setSelected(customSelected);
        }
    };

    const onSelectChange = (value) => {
        setCustomSelected(value);
        setSelected(value);
    };

    return ({
        loading, selected, learningPlans,
        render:
        <Row>
            <Col xs="6">
                <h5>Learning Plans</h5>
            </Col>
            <Col xs="6">
                <div>
                    <input className='radio-margin' type='radio' name='learningPlansRadio' onChange={() => onChange(true)} checked={selectAll}/> Select All
                    <input className='radio-margin' type='radio' name='learningPlansRadio' onChange={() => onChange(false)} checked={!selectAll}/> Custom Selection
                </div>
                <div>
                    {!selectAll ? <Select isMulti value={selected} options={learningPlans} onChange={onSelectChange} /> : null}
                </div>
            </Col>
        </Row>
    });
}