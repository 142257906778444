import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ReportNavMenu from './ReportNavMenu';
import { NavBarProvider } from '../context/NavBarProvider';
import useAuth from '../hooks/useAuth';

export default function ReportLayout() {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();

    if (auth.viewLevel === "adminreports" && auth.role !== "SuperAdmin" && auth.role !== "PowerUser") {
        navigate('/unauthorized');
    }

    return (
        <NavBarProvider>
          <ReportNavMenu/> 
          <Outlet />
        </NavBarProvider>
    );
}
