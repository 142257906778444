import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardLink,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import useAuth from "../hooks/useAuth";

export default function Home() {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();

    if (auth.viewLevel === "myreports") {
        navigate('/myreports');
    }
    if (auth.viewLevel === "adminreports" && auth.role !== "SuperAdmin" && auth.role !== "PowerUser") {
        navigate('/unauthorized');
    }

  const isKy = auth.isKy;
  const isCa = auth.isCa;

  return (
    <Container>
      <Card>
        <CardBody>
          <Row>
            <Col className="col-4 flex-fill">
              <Card className="border-0">
                <CardBody className="text-center">
                  <CardTitle tag="h5">ISO Summary Report</CardTitle>
                  <CardText>
                    Report for generating summarized training completions based
                    on ISO Codes.
                  </CardText>
                  <Link
                    to="/isosummary/form"
                    className="btn btn-primary mx-auto"
                  >
                    View
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-4 flex-fill">
              <Card className="border-0">
                <CardBody className="text-center">
                  <CardTitle tag="h5">ISO Detail Report</CardTitle>
                  <CardText>
                    Report for generating detailed training completions based on
                    ISO Codes.
                  </CardText>
                  <Link
                    to="/isodetail/form"
                    className="btn btn-primary mx-auto"
                  >
                    View
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-4 flex-fill">
              <Card className="border-0">
                <CardBody className="text-center">
                  <CardTitle tag="h5">
                    <span className="newFeature">
                      Detailed Completions Report
                    </span>
                  </CardTitle>
                  <CardText>
                    Completion data and training hours per learner (active
                    enrollments)
                  </CardText>
                  <Link
                    to="/detailedcompletions/form"
                    className="btn btn-primary mx-auto"
                  >
                    View
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-4 flex-fill">
              <Card className="border-0">
                <CardBody className="text-center">
                  <CardTitle tag="h5">Learning Plan Report</CardTitle>
                  <CardText>Report for generating Learning Plans.</CardText>
                  <Link
                    to="/learningplan/form"
                    className="btn btn-primary mx-auto"
                  >
                    View
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-4 flex-fill">
              <Card className="border-0">
                <CardBody className="text-center">
                  <CardTitle tag="h5">Observation Checklist Report</CardTitle>
                  <CardText>
                    Report for generating approved Observation Checklists.
                  </CardText>
                  <Link
                    to="/checklist/form"
                    className="btn btn-primary mx-auto"
                  >
                    View
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-4 flex-fill">
              {isKy ? (
                <Card className="border-0">
                  <CardBody className="text-center">
                    <CardTitle tag="h5">
                      Kentucky Training Detail Report
                    </CardTitle>
                    <CardText>
                      Report for generating detailed training completions based
                      on Kentucky codes
                    </CardText>
                    <Link
                      to="/kentuckydetail/form"
                      className="btn btn-primary mx-auto"
                    >
                      View
                    </Link>
                  </CardBody>
                </Card>
              ) : null}
              {isCa ? (
                <Card className="border-0">
                  <CardBody className="text-center">
                    <CardTitle tag="h5">CAL-JAC Report</CardTitle>
                    <CardText>
                      Report for generating training completions for California
                      Joint Apprenticeship Committee
                    </CardText>
                    <Link
                      to="/californiajac/form"
                      className="btn btn-primary mx-auto"
                    >
                      View
                    </Link>
                  </CardBody>
                </Card>
              ) : null}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
}
