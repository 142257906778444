import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireElevatedAuth = () => {
    const { auth } = useAuth();

    return (
        (auth?.user && (auth?.role === "SuperAdmin" || auth?.role === "PowerUser")) ? <Outlet /> : <Navigate to="/unauthorized" />
    );
}

export default RequireElevatedAuth;