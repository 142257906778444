import { Link, useNavigate } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardLink,
    CardText,
    CardTitle,
    Col,
    Container,
    Row,
} from "reactstrap";
import useAuth from "../hooks/useAuth";

export default function Home() {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();

    if (auth.viewLevel === "adminreports") {
        navigate('/');
    }

    return (
        <Container>
            <Card>
                <CardBody>
                    <Row>
                        <Col className="col-4 flex-fill">
                            <Card className="border-0">
                                <CardBody className="text-center">
                                    <CardTitle tag="h5">My Learning Plan Progress Report</CardTitle>
                                    <CardText>See your progress on your Learning Plans.</CardText>
                                    <Link
                                        to="/myreports/learningplan/form"
                                        className="btn btn-primary mx-auto"
                                    >
                                        View
                                    </Link>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="col-4 flex-fill">
                        </Col>
                        <Col className="col-4 flex-fill">
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Container>
    );
}
