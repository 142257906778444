import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';

const LOGIN_URL = '/api/auth/login';

const TryLogin = async (searchParams, setAuth, navigate) => {
    try {
        await axios.post(LOGIN_URL,
            JSON.stringify({
                userid: searchParams.get("user_id"),
                username: searchParams.get("username"),
                authcode: searchParams.get("auth_code"),
                hash: searchParams.get("hash")
            })
        )
            .then(response => {
                if (response?.data.role !== "SuperAdmin" && response?.data.role !== "PowerUser") {
                    navigate('/unauthorized');
                }
                setAuth({ user: searchParams.get("user_id"), role: response?.data.role, accessToken: response?.data.accessToken, isKy: response?.data.isKy, isCa: response?.data.isCa, viewLevel: "adminreports" });

                navigate('/');
            });
    }
    catch (err) {
        console.log(err);
        navigate('/unauthorized');
    }
}

function Login() {
    const { setAuth } = useAuth();

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    useEffect(() => {
        TryLogin(searchParams, setAuth, navigate);
    }, [searchParams])
}

export default Login;
