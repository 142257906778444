import React from 'react';
import { Navbar, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import './NavMenu.css';
import useAuth from '../hooks/useAuth';

export default function NavMenu() {
    const { auth, setAuth } = useAuth();

    var path = auth.viewLevel === "adminreports" ? "" : "/myreports";

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container>
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                        <NavLink className="nav-link" to={`${path}/`}>Reports</NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink className="nav-link" to={`${path}/reports`}>User Reports</NavLink>
                        </NavItem>
                    </ul>
                    <div className="nav-link">Your data is refreshed in the background every hour.</div>
            </Navbar>
        </header>
    );
}
