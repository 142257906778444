import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { read } from 'xlsx';
import DataGrid from "react-data-grid";
import 'react-data-grid/lib/styles.css';
import { AOAColumn, Row, ws_to_rdg } from '../PreviewLogic';
import useFilter from '../../../hooks/useFilter';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import EmptyRowsRenderer from '../../EmptyRowsRenderer';

export default function ISOSummaryPreview() {
    const { filter } : any = useFilter();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [rows, setRows] = useState<Row[]>([]);
    const [columns, setColumns] = useState<AOAColumn[]>([]);
    const axiosPrivate = useAxiosPrivate();

    const getPreview = async () => {
        if(filter?.startDate && filter?.endDate){
            var start = moment(filter?.startDate, 'YYYY-MM-DD');
            var end = moment(filter?.endDate, 'YYYY-MM-DD');
            if(moment.duration(end.diff(start)) > moment.duration(1, 'year')){
                setError(true);
                setErrorMessage("Cannot preview date range greater than 1 year");
                setLoading(false);
                return;
            }            
        }
        if(filter?.users.length > 250){
            setError(true);
            setErrorMessage("Cannot preview more than 250 users at a time");
            setLoading(false);
            return;
        }
        if(filter?.groups.length > 25){
            setError(true);
            setErrorMessage("Cannot preview more than 25 groups at a time");
            setLoading(false);
            return;
        }

        const sendfilter = (
            (
                { users, groups, isoCodes, headers, startDate, endDate }) =>
                ({ users, groups, isoCodes, headers, startDate, endDate })
        )(filter);

        try {
            await axiosPrivate.post('api/reports/isosummary/file', sendfilter, {
                        responseType: 'arraybuffer'
                    }).then((response) => {
                        const wb = read(response.data);
                        const ws = wb.Sheets[wb.SheetNames[0]];

                        let grid = ws_to_rdg(ws)

                        setRows(grid.rows)
                        setColumns(grid.columns)
        });
    }
        catch(err){
            console.log(err);
            setError(true);
            setErrorMessage("Error getting preview");
        }
        setLoading(false);
    }

    useEffect(() => {
        getPreview();
    }, []);

    return ( 
        loading ? <Spinner variant="primary" animation="border" role="status" className='custom-spinner'/> :
            !error ? <DataGrid
                columns={columns} rows={rows}
                onRowsChange={setRows}
                renderers={{ noRowsFallback: <EmptyRowsRenderer /> }}
                className="custom-grid rdg-light" /> : 
        <div>{errorMessage}</div>
    );
}