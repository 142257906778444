import React, { useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Spinner } from 'reactstrap';
import useFilter from '../../../hooks/useFilter';
import useNavBar from '../../../hooks/useNavBar';
import LearningPlans from '../../LearningPlans';
import useAuth from "../../../hooks/useAuth";

export default function LearningPlanReportForm() {
    const { auth, setAuth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const learningPlans = LearningPlans();
    
    const { filter, setFilter } = useFilter();
    const { loading, setLoading } = useNavBar();
    

    const headers = 
    [
        "Full Name",
        "Learning Plan Name",
        "Completion Percentage",
        "Enrollment Status",
        "Hours Required",
        "Hours Completed",
        "Hours Remaining",
        "Course",
        "Category",
        "Training Code",
        "Course Type",
        "Enrollment Date",
        "Active From",
        "Active To",
        "Course Completion Date"
    ];    

    useEffect(() => {
        if(!learningPlans.loading){
            setLoading(false);
        }
        setFilter({
            users: [auth.user],
            groups: [],
            learningPlans: learningPlans.selected.map(learningPlan => learningPlan.value),
            headers: headers,

            formLearningPlans: learningPlans.learningPlans
        });
    }, [
        learningPlans.loading,
        learningPlans.selected, 
        learningPlans.learningPlans]); 

    const handleSubmit = async (e) => {
        e.preventDefault();

        const sendfilter = (
            (
                { users, groups, learningPlans, headers }) =>
                ({ users, groups, learningPlans, headers })
        )(filter);

        try{
            await axiosPrivate.post('api/myreports/mylearningplan', sendfilter);
            navigate('/myreports/reports');
        }
        catch(err){
            console.log(err);
        }
    }

    return (
        loading ? <Spinner variant="primary" animation="border" role="status" className='custom-spinner'/> :
        <Container>
            <Col>
                    <FormGroup>
                        <Card>
                            <CardBody>
                                <FormGroup>
                                    Your filter selections will be reflected on the Preview page.
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </FormGroup>   
                <FormGroup>
                    <Card>
                        <CardBody>                 
                            <FormGroup>
                                {learningPlans.render}
                            </FormGroup>
                        </CardBody>
                    </Card>
                </FormGroup>  
            </Col>
            <footer>
                <Form onSubmit={handleSubmit}>
                    <Button color="primary">Generate Report</Button>
                    <Link to="/myreports/">
                        <Button className='btn-danger'>Back</Button>
                    </Link>
                </Form>
            </footer>
        </Container>
    );
}